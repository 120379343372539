import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {Countries} from './../../Models';

// styles
import './styles.css';

const CountryScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	useEffect(() => {
		(async () => {
			if (id) {
				const d = await Countries.get.id(id);
				const country = d;
				setName(country.name);
				setCode(country.code);
			}
			setLoading(false);
		})();
	}, [id,setName,setCode,setLoading]);
	const save = async (e) => {
		e.preventDefault();
		const data = {name,code};
		if (id === 0) {
			const res = await Countries.add(data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
		else {
			const res = await Countries.update(id, data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить страну' : 'Редактирование страны'} link='countries'
						controlAdd={<a href="/country" className="add">Добавить</a>}  />
					<div className="form-container">
						<div className="cell">
							<label>
								<span>Название</span>
								<input type="text" {...bindName} placeholder="Название" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Код</span>
								<input type="text" {...bindCode} placeholder="Код" required />
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default CountryScreen;
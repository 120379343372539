import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {AccountCheckLists} from '../../Models';


const AccountCheckListsScreen = () => {
	const [checkLists, setCheckLists] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const dataGet = async () => {
			const d = await AccountCheckLists.get.all();
			setCheckLists(dataSet(d));
			setDataFull(d);
		};
		dataGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'cities', ['_id','name','description','code','path']);
	return (
		<Template>
			{checkLists === null ? <Loader /> :
				<DataGrid
					title={'Шаги для чек-листа аккаунта'}
					keys={['Название','Описание','Код','Путь']}
					link={'accountchecklist'}
					data={checkLists}
					dataFull={dataFull}
					controlAdd={<Link to={'/accountchecklist'}>Добавить</Link>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default AccountCheckListsScreen;
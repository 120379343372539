import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start				from './Screens/Start';
import Login				from './Screens/Login';

import Dashboard			from './Screens/Dashboard';

import Clients				from './Screens/Clients';
import Client				from './Screens/Client';

import Users				from './Screens/Users';
import User					from './Screens/User';

import Cities				from './Screens/Cities';
import City					from './Screens/City';
import Coutries				from './Screens/Countries';
import Country				from './Screens/Country';
import Languages			from './Screens/Languages';
import Language				from './Screens/Language';
import AccountCheckLists	from './Screens/AccountCheckLists';
import AccountCheckList		from './Screens/AccountCheckList';

import Settings				from './Screens/Settings';
import Setting				from './Screens/Setting';

import Error				from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/dashboard' component={Dashboard} exact />

			<Route path='/cities' component={Cities} exact />
			<Route path='/city' component={City} exact />
			<Route path='/city/:id' component={City} exact />
			<Route path='/countries' component={Coutries} exact />
			<Route path='/country' component={Country} exact />
			<Route path='/country/:id' component={Country} exact />
			<Route path='/languages' component={Languages} exact />
			<Route path='/language' component={Language} exact />
			<Route path='/language/:id' component={Language} exact />
			<Route path='/accountchecklists' component={AccountCheckLists} exact />
			<Route path='/accountchecklist' component={AccountCheckList} exact />
			<Route path='/accountchecklist/:id' component={AccountCheckList} exact />

			<Route path='/clients' component={Clients} exact />
			<Route path='/client' component={Client} exact />
			<Route path='/client/:id' component={Client} exact />

			<Route path='/users' component={Users} exact />
			<Route path='/user' component={User} exact />
			<Route path='/user/:id' component={User} exact />

			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/setting/:id' component={Setting} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;
import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Countries} from './../../Models';

const CountriesScreen = () => {
	const [countries, setCountries] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const pagesGet = async () => {
			const d = await Countries.get.all();
			setCountries(dataSet(d));
			setDataFull(d);
		};
		pagesGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'countries', ['_id','name','code']);
	return (
		<Template>
			{countries === null ? <Loader /> :
				<DataGrid
					title={'Страны'}
					keys={['Название','Код']}
					link={'country'}
					data={countries}
					dataFull={dataFull}
					controlAdd={<Link to={'/country'}>Добавить</Link>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default CountriesScreen;
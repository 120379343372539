import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';

// models
import {AccountCheckLists} from '../../Models';

// styles
import './styles.css';


const AccountCheckListScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:code, setValue:setCode, bind:bindCode} = useInput('');
	const {value:path, setValue:setPath, bind:bindPath} = useInput('');
	const {value:sortOrder, setValue:setSortOrder, bind:bindSortOrder} = useInput('');
	useEffect(() => {
		(async () => {
			if (id) {
				const d = await AccountCheckLists.get.id(id);
				const checklist = d;
				setName(checklist.name);
				setCode(checklist.code);
				setPath(checklist.path);
				setDescription(checklist.description);
				setSortOrder(checklist.sortOrder);
			}
			setLoading(false);
		})();
	}, [id,setName,setDescription,setCode,setPath,setSortOrder,setLoading]);
	const save = async (e) => {
		e.preventDefault();
		const data = {name,description,code,path,sortOrder};
		if (id === 0) {
			const res = await AccountCheckLists.add(data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
		else {
			const res = await AccountCheckLists.update(id, data);
			if (res) alertShow('Операция прошла успешно!');
			else alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить позицию' : 'Редактирование позиции'} link='accountchecklists'
						controlAdd={<a href="/accountchecklist" className="add">Добавить</a>}  />
					<div className="form-container">
						<div className="cell">
							<label>
								<span>Название</span>
								<input type="text" {...bindName} placeholder="Название" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Описание</span>
								<textarea placeholder="Описание шага" {...bindDescription}></textarea>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Код</span>
								<input type="text" {...bindCode} placeholder="" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Путь, ссылка</span>
								<input type="text" {...bindPath} placeholder="/" className="double" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Порядок сортировки</span>
								<input type="text" {...bindSortOrder} placeholder="0" />
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</Template>
	);
};

export default AccountCheckListScreen;